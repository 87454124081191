<template>
  <div>
    <v-alert text dense color="info" border="left" style="font-size: 14px">
      1. Chi tiết đơn: Quét lại Tracking / Mã vận chuyển <br>
      2. Mất niêm phong: SSC-MNP-SSC → Tracking / Mã vận chuyển <br>
      3. Xóa đơn nhận hoàn: SSC-RMRT-SSC → Tracking / Mã vận chuyển → SSC-RMRT-SSC <br>
    </v-alert>
    <v-text-field :label="$t('labels.tracking_or_delivery_order_id')" dense outlined clearable hide-details autofocus
      @keyup.enter="getOrder" v-model="tracking"></v-text-field>

    <v-simple-table class="mb-5" v-if="groupOrderByDelivery && groupOrderByDelivery.length > 0">
      <template v-slot:default>
        <thead class="v-data-table-header">
          <tr>
            <th role="columnheader" class="text-center">ĐVVC</th>
            <th role="columnheader" class="text-center" style="width: 135px">Đơn hoàn</th>
            <th role="columnheader" class="text-center" style="width: 135px">Mất niêm phong</th>
            <th role="columnheader" class="text-center" style="width: 120px">Thiếu</th>
            <th role="columnheader" class="text-center" style="width: 120px">Hư hại</th>
            <th role="columnheader" class="text-center"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(group, gk) in groupOrderByDelivery" :key="`${group.name}_${gk}`" class="text-center">
            <td>{{ group.name }}</td>
            <td>{{ group.total }}</td>
            <td>
              <div :class="{ 'gr-error-count': group.lost_seal != 0 }">{{ group.lost_seal }}</div>
            </td>
            <td>
              <div :class="{ 'gr-error-count': group.missing != 0 }">{{ group.missing }}</div>
            </td>
            <td>
              <div :class="{ 'gr-error-count': group.damage != 0 }">{{ group.damage }}</div>
            </td>
            <td style="width: 220px"><!-- border-bottom: 0; :rowspan="groupOrderByDelivery.length" v-if="gk === 0" -->
              <v-btn color="warning" class="mr-2" small @click.prevent="cancelGroup(group)" outlined>Làm lại</v-btn>
              <v-btn color="success" :disabled="group.is_not_checked > 0" small @click.prevent="confirmGroup(group)">Xác
                nhận</v-btn>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <v-simple-table class="mb-5" v-if="orders && orders.length > 0">
      <template v-slot:default>
        <thead class="v-data-table-header">
          <tr>
            <th role="columnheader" class="text-center">Tracking</th>
            <th role="columnheader" class="text-center">Mất niêm phong</th>
            <th role="columnheader" class="text-center">Thiếu</th>
            <th role="columnheader" class="text-center">Hư hại</th>
            <th role="columnheader" class="text-center">Khách hàng</th>
            <th role="columnheader" class="text-center">Mã vận chuyển</th>
            <th role="columnheader" class="text-center">TT. Kho</th>
            <th role="columnheader" class="text-center">TT. Vận chuyển</th>
            <th role="columnheader" class="text-center">TT. Tổng hợp</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="od in reverseOrders" :key="od.tracking_id" class="text-center">
            <td>
              <v-btn :color="od.is_lost_seal && !od.is_checked ? 'red' : 'primary'" outlined
                @click="showDialogCheckingOrder(od)">
                {{ od.tracking_id }}
              </v-btn>
            </td>
            <td>
              <v-switch class="switch-center" v-model="od.is_lost_seal" readonly disabled></v-switch>
            </td>
            <td>{{ od.missing }}</td>
            <td>{{ od.damage }}</td>
            <td>{{ od.customer_company_name }}</td>
            <td>{{ od.short_delivery_order_id || od.delivery_order_id }}</td>
            <td>{{ od.goods_issue_state_name }}</td>
            <td>{{ od.delivery_partner_state }}</td>
            <td>{{ od.delivery_state_name }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <v-divider class="my-10"></v-divider>
    <ReturnReceiveHistory />

    <v-dialog v-model="dialogCheckingOrder" persistent max-width="1200px">
      <ReturnReceiveDialogChecking v-if="dialogCheckingOrder && checkingOrder && checkingOrder.id"
        :checking-order="checkingOrder" @closeDialog="closeDialogCheckingOrder" @submit="submitDialogCheckingOrder" />
    </v-dialog>

    <v-dialog v-model="dialogConfirm" persistent max-width="350px">
      <ConfirmBox v-if="dialogConfirm" title="Đơn hàng mất niêm phong"
        :description="`Bạn có chắc chắn muốn xác nhận đơn hàng ${lastOrderScanned.tracking_id} mất niêm phong không?`"
        :label="$t('labels.tracking_or_delivery_order_id')" :placeholder="$t('labels.confirm_return_import_note')"
        :codes="checkingCodes" @cancel="cancelConfirm" @confirm="confirmLostSeal" />
    </v-dialog>

    <v-dialog v-model="dialogConfirmRemove" persistent max-width="350px">
      <ConfirmRemoveBox v-if="dialogConfirmRemove" title="Xóa đơn nhận hoàn"
        :description="`Quét Tracking/Mã vận chuyển. Sau đó quét lại mã xác nhận để xóa đơn khỏi danh sách nhận hoàn`"
        label="Tracking/Mã vận chuyển & Mã xác nhận" placeholder="Tracking/Mã vận chuyển & Mã xác nhận"
        :codes="removeCodes" @cancel="cancelConfirmRemove" @confirm="confirmRemove" />
    </v-dialog>
  </div>
</template>

<script>
import { httpClient } from "@/libs/http";
import { BARCODE_MAT_NIEM_PHONG, BARCODE_REMOVE } from "@/libs/const";
import moment from "moment";

export default {
  name: "ReturnReceive",
  components: {
    ConfirmBox: () => import('@/components/common/ConfirmBox'),
    ConfirmRemoveBox: () => import('@/components/common/ConfirmRemoveBox'),
    ReturnReceiveDialogChecking: () => import('@/components/order_online/ReturnReceiveDialogChecking'),
    ReturnReceiveHistory: () => import('@/components/order_online/ReturnReceiveHistory'),
  },
  data: () => ({
    tracking: null,
    confirm_tracking: null,
    orders: [],
    checkingOrder: {},
    dialogCheckingOrder: false,
    isLoading: false,
    is_lost_seal: false,
    isDamage: false,
    dialogConfirm: false,
    dialogConfirmRemove: false,
  }),
  computed: {
    groupOrderByDelivery() {
      const groups = {}
      this.orders.forEach(order => {
        if (!groups[order.delivery_short_name]) {
          groups[order.delivery_short_name] = {
            name: order.delivery_short_name,
            id_delivery_company: order.id_delivery_company,
            total: 0,
            lost_seal: 0,
            is_not_checked: 0,
            missing: 0,
            damage: 0,
            orders: []
          }
        }
        groups[order.delivery_short_name].total += 1
        groups[order.delivery_short_name].lost_seal += (order.is_lost_seal ? 1 : 0)
        groups[order.delivery_short_name].missing += (order.missing || 0)
        groups[order.delivery_short_name].damage += (order.damage || 0)
        groups[order.delivery_short_name].is_not_checked += (order.is_lost_seal && !order.is_checked ? 1 : 0)

        const orderItems = [...order.items]
        const orderNotes = []
        if (order.items && order.items.length > 0) {
          for (let j = 0; j < order.items.length; j++) {
            const o = orderItems[j]
            let ons = []
            if (order.is_lost_seal || order.missing || order.damage) {
              ons = [
                `SKU: ${o.sku}`,
                `Xuất: ${o.quantity}`,
                `Nhận hoàn: ${order.is_checked ? (o.receive_quantity > o.quantity ? o.quantity : o.receive_quantity) : o.quantity}`,
                `Thiếu: ${order.is_checked ? ((o.quantity - o.receive_quantity) > 0 ? (o.quantity - o.receive_quantity) : 0) : 0}`,
                `Hư hại: ${order.is_checked ? o.damage : 0}`,
              ]
            }
            if (o.reason) {
              if (typeof o.reason === "string") {
                ons.push(`Ghi chú: ${o.reason}`)
              } else {
                ons.push(`Ghi chú: ${o.reason.join(', ')}`)
              }
            }
            if (o.note) {
              ons.push(`${o.note}`)
            }
            const oNote = ons.join(', ')
            orderNotes.push(oNote)
          }
        }
        groups[order.delivery_short_name].orders.push({
          id: order.id,
          tracking_id: order.tracking_id,
          is_lost_seal: order.is_lost_seal,
          is_wet_or_damaged: order.damage && order.damage > 0 ? 1 : 0,
          note: orderNotes.join(`; \n`),
        })
      })
      return Object.values(groups)
    },
    lastOrderScanned() {
      if (this.orders.length === 0) {
        return {}
      }
      return this.orders[this.orders.length - 1]
    },
    removeCodes() {
      return [BARCODE_REMOVE]
    },
    checkingCodes() {
      const codes = []
      if (!this.lastOrderScanned || !this.lastOrderScanned.tracking_id) {
        return codes
      }
      if (this.lastOrderScanned.tracking_id) {
        codes.push(this.lastOrderScanned.tracking_id)
      }
      if (this.lastOrderScanned.delivery_order_id) {
        codes.push(this.lastOrderScanned.delivery_order_id)
      }
      if (this.lastOrderScanned.short_delivery_order_id) {
        codes.push(this.lastOrderScanned.short_delivery_order_id)
      }
      return [...codes]
    },

    reverseOrders() {
      return [...this.orders].reverse()
    }
  },
  methods: {
    cancelConfirm() {
      this.is_lost_seal = false
      this.dialogConfirm = false
      this.isLoading = false
    },
    async updateReceive(order) {
      try {
        await httpClient.post('/order-online-return-receive-update-items', {
          id_goods_issue_detail: order.id,
          is_lost_seal: order.is_lost_seal ? 1 : 0,
          is_checked: order.is_checked ? 1 : 0,
          damage: order.damage,
          missing: order.missing,
          note: order.note,
          items: JSON.stringify(order.items || []),
        })
      } catch (e) {
        const errMsg = e.response && e.response.data && e.response.data.error && e.response.data.error.message || null
        this.$vToastify.error(errMsg)
      }
    },
    confirmLostSeal(confirm_tracking) {
      this.tracking = confirm_tracking
      this.getOrder()
    },
    async getOrder() {
      /*if (this.dialogConfirm) {
        if (![this.lastOrderScanned.tracking_id, this.lastOrderScanned.delivery_order_id, this.lastOrderScanned.short_delivery_order_id].includes(this.confirm_tracking)) {
          this.$root.$emit('playErrorAudio')
          this.confirm_tracking = null
          return false
        }
        this.tracking = this.confirm_tracking
      }*/
      if (!this.tracking) {
        return false
      }
      if (this.isLoading) {
        this.isLoading = false
        this.$vToastify.warning(this.$t('messages.loading'));
        return false
      }
      this.isLoading = true

      if (this.tracking === BARCODE_MAT_NIEM_PHONG) {
        this.isLoading = false
        this.tracking = null
        if (!this.lastOrderScanned || !this.lastOrderScanned.tracking_id) {
          this.$vToastify.error('Cần quét mã đơn hàng trước');
          this.$root.$emit('playErrorAudio')
          return false
        }
        this.is_lost_seal = true
        this.dialogConfirm = true
        this.$root.$emit('playSuccessAudio')
        return false
      }

      if (this.tracking === BARCODE_REMOVE) {
        this.isLoading = false
        this.tracking = null
        this.dialogConfirmRemove = true
        return false
      }

      if (this.is_lost_seal) {
        if (
          this.lastOrderScanned && this.lastOrderScanned.id &&
          ([this.lastOrderScanned.tracking_id, this.lastOrderScanned.delivery_order_id, this.lastOrderScanned.short_delivery_order_id].includes(this.tracking))
        ) {
          const orders = [...this.orders]
          this.orders = orders.map(od => {
            if (od.id === this.lastOrderScanned.id) {
              od.is_lost_seal = this.is_lost_seal
              od.is_checked = false
              this.updateReceive(od)
            }
            return od
          })
          this.is_lost_seal = false
          this.tracking = null
          this.isLoading = false
          this.dialogConfirm = false
          this.$root.$emit('playSuccessAudio')
        } else {
          this.tracking = null
          this.isLoading = false
          this.$vToastify.error('Tracking/Mã vận chuyển không đúng')
          this.$root.$emit('playErrorAudio')
        }
        return false
      }

      try {
        const { data } = await httpClient.post('/order-online-return-receive-get-order', {
          tracking: this.tracking
        })
        // data.is_lost_seal = this.is_lost_seal
        this.isLoading = false
        this.tracking = null

        const order = this.orders.find(o => o.id === data.id)
        if (order) {
          this.isLoading = false
          this.showDialogCheckingOrder(order)
          // this.$vToastify.error('Đơn hàng đã quét')
          // this.$root.$emit('playErrorAudio')
          return false
        }
        data.missing = 0
        data.damage = 0
        const cData = await this.getOrderDetail(data)
        await this.updateReceive(cData)
        this.orders.push({ ...cData })
        this.$root.$emit('playSuccessAudio')
      } catch (e) {
        this.tracking = null
        this.isLoading = false
        this.is_lost_seal = false
        const errMsg = e.response && e.response.data && e.response.data.error && e.response.data.error.message || null
        this.$vToastify.error(errMsg)
        this.$root.$emit('playErrorAudio')
      }
    },
    showDialogCheckingOrder(order) {
      this.checkingOrder = { ...order }
      this.dialogCheckingOrder = true
    },
    async submitDialogCheckingOrder(order) {
      const orders = [...this.orders]
      this.orders = orders.map(od => {
        if (od.id === order.id) {
          od = { ...order }
          this.updateReceive(od)
        }
        return od
      })
    },
    async closeDialogCheckingOrder() {
      this.checkingOrder = {}
      this.dialogCheckingOrder = false
      this.isLoading = false
    },
    async confirmGroup(group) {
      if (group.is_not_checked > 0) {
        this.$vToastify.error('Chưa xác nhận xong các đơn hàng mất niêm phong')
        this.$root.$emit('playErrorAudio')
        return false
      }

      if (this.isLoading) {
        this.$vToastify.warning(this.$t('messages.loading'));
        return false
      }
      this.isLoading = true

      try {
        const { data } = await httpClient.post('/order-online-return-receive-create-session', group)
        this.isLoading = false
        this.$root.$emit('playSuccessAudio')
        await this.downloadFileReturn(data)
        const orders = [...this.orders]
        this.orders = orders.filter(od => {
          return od.id !== (group.orders.find(o => o.id === od.id) || {}).id
        })
      } catch (e) {
        const errMsg = e.response && e.response.data && e.response.data.error && e.response.data.error.message || null
        this.$vToastify.error(errMsg)
        this.isLoading = false
        this.$root.$emit('playErrorAudio')
      }
    },
    async cancelGroup(group) {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t('messages.loading'));
        return false
      }
      this.isLoading = true

      try {
        await httpClient.post('/order-online-return-receive-cancel-multiple', group)
        this.isLoading = false
        this.$root.$emit('playSuccessAudio')
        const orders = [...this.orders]
        this.orders = orders.filter(od => {
          return od.id !== (group.orders.find(o => o.id === od.id) || {}).id
        })
      } catch (e) {
        const errMsg = e.response && e.response.data && e.response.data.error && e.response.data.error.message || null
        this.$vToastify.error(errMsg)
        this.isLoading = false
        this.$root.$emit('playErrorAudio')
      }
    },
    cancelConfirmRemove() {
      this.dialogConfirmRemove = false
    },
    confirmRemove(confirm_code) {
      const order = [...this.orders].find(od => [od.tracking_id, od.delivery_order_id, od.short_delivery_order_id].includes(confirm_code))
      if (!order) {
        this.$vToastify.error(this.$t('labels.order_not_found'))
        this.$root.$emit('playErrorAudio')
        return false
      }
      this.cancelItem(order)
    },
    async cancelItem(order) {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t('messages.loading'));
        return false
      }
      this.isLoading = true

      try {
        await httpClient.post('/order-online-return-receive-cancel-one', order)
        this.isLoading = false
        this.$root.$emit('playSuccessAudio')
        const orders = [...this.orders]
        this.orders = orders.filter(od => od.id !== order.id)
      } catch (e) {
        const errMsg = e.response && e.response.data && e.response.data.error && e.response.data.error.message || null
        this.$vToastify.error(errMsg)
        this.isLoading = false
        this.$root.$emit('playErrorAudio')
      }
    },
    async getCurrentReceive() {
      try {
        const { data } = await httpClient.post('/order-online-return-receive-get-current')
        this.orders = data.map(i => ({
          ...i,
          items: JSON.parse(i.items || '[]')
        }))
      } catch (e) {
        const errMsg = e.response && e.response.data && e.response.data.error && e.response.data.error.message || null
        this.$vToastify.error(errMsg)
      }
    },
    async downloadFileReturn(id) {
      const filename = `A4_bien_ban_nhan_hoan_${moment().unix()}`
      this.downloadExcelFile(`/order-online-return-receive-download-file`, { id }, filename).then((res) => {
        console.log(res)
        this.isLoading = false
      }).catch((err) => {
        console.log(err)
        this.isLoading = false
      })
    },
    async getOrderDetail(order) {
      order.items = JSON.parse(order.items || '[]')
      if (order.items && order.items.length > 0) {
        let total_quantity = 0
        const items = order.items.map(d => {
          total_quantity += d.quantity
          return d
        })
        order.items = [...items]
        return { ...order, total_sku: items.length, total_quantity }
      }
      try {
        const { data } = await httpClient.post('/order-online-return-receive-get-order-items', {
          id: order.id
        })
        let total_quantity = 0
        const items = data.map(d => {
          total_quantity += d.quantity
          return { ...d, receive_quantity: 0, damage: 0, is_in_order: true }
        })
        order.items = [...items]
        return { ...order, total_sku: items.length, total_quantity }
      } catch (e) {
        return order
      }
    }
  },
  mounted() {
    this.getCurrentReceive()
  }
}
</script>

<style scoped></style>
